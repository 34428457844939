<template src="./promousagereport.html"></template>
<style scoped src="./promousagereport.css"></style>

<script>
import Loading from 'vue-loading-overlay'
import applicationConstants from '@/constants/AppConstants.vue'
import reportServices from '@/services/ReportService.vue'
import Modal from '@/components/modal/notification/modal'
import utils from '@/utils/utils'
import moment from 'moment'
import JsonToExcel from 'vue-json-excel'
import Pagination from '@/components/vtable/vtpagination.vue'

export default {
    name: 'PromoUsageReport',
    components: {
        Loading, Modal, Pagination, JsonToExcel
    },
    data () {
        return {
            enablePaginateData: applicationConstants.enableLazyLoading,
            loaderVisible: false,
            alertObject: {
                errorCode: null,
                errorDescription: null,
                errorDetails: []
            },
            expiryDateFrom: null,
            expiryDate: null,
            promoType: "",
            issuedTo: null,
            promoResponseData: [],
            promoTableDataColumns: ['PromoCodeType', 'Name', 'Code', 'IssueNumber', 'IssuedToEmail', 'Nomination', 'ExpiryDate', 'RedeemedAmount', 'RedeemedDate', 'TransactionReference'],
            promoTableOptions: {},
            csvFields: {
                "PromoCodeType": "PromoCodeType",
                "Name": "Name",
                "Code": "Code",
                "IssueNumber": "IssueNumber",
                "IssuedTo": "IssuedTo",
                "IssuedToEmail": "IssuedToEmail",
                "ReasonIssued": "ReasonIssued",
                "Nomination": "Nomination",
                "ExpiryDate": "ExpiryDate",
                "RedeemedAmount": "RedeemedAmount",
                "RedeemedDate": "RedeemedDate",
                "Currency": "Currency",
                "PromoTxnReference": "PromoTxnReference",
                "TransactionReference": "TransactionReference",
                "TransactionAmount": "TransactionAmount",
                "RedemptionStatus": "RedemptionStatus"
            }
        }
    },
    methods: {
        async getPromoUsageReportData() {
            this.loaderVisible = true
            let fDate = this.expiryDateFrom ? (utils.methods.LocalToUTC(moment(moment(this.expiryDateFrom)._d).format('MM-DD-YYYY hh:mm:ss'))) : null 
            let tDate = this.expiryDate ? (utils.methods.LocalToUTC(moment(moment(this.expiryDate)._d).format('MM-DD-YYYY hh:mm:ss'))) : null 
            reportServices.methods.GetPromoUsageSummary({
                ExpirationDateFrom: fDate,
                ExpirationDateTo: tDate,
                IssuedTo: this.issuedTo ? this.issuedTo : null,
                PromoCodeType: this.promoType ? this.promoType : null
            })
            .then (res => {
                this.promoResponseData = res.csGetPromoCodeUsageResponse.PromoCodeUsageData?.RptPromocodeUsageData
                this.promoResponseData = this.promoResponseData ? (Array.isArray(this.promoResponseData) ? this.promoResponseData : [this.promoResponseData]) : []
                if (this.enablePaginateData) {
                    this.$refs.paginationRef.selectedPage = 1
                } else {
                    this.$refs.vueClientTable.setPage(1)
                }
                this.loaderVisible = false
            })
            .catch(err=> {
                this.promoResponseData = []
                this.loaderVisible = false
                this.alertObject = err
                this.$bvModal.show(this.$refs.childModal.id)
            })
        },
        reponseModifier (promoData) {
            if (promoData) {
                promoData.forEach(promo => {
                    Object.keys(promo).map(key => promo[key] = promo[key]?._text ? promo[key]._text : null)
                })
            }
            return promoData ? promoData : []
        },
        clearAll () {
            this.expiryDateFrom = null
            this.expiryDate = null
            this.promoType = ""
            this.issuedTo = null
        },
        exportTableData() {
            if (this.promoResponseData.length > 0) {
                return this.reponseModifier(JSON.parse(JSON.stringify(this.promoResponseData)))
            }
        }
    },
    computed: {
        promoTableData () {
            let promoData = []
            if (this.promoResponseData && this.promoResponseData.length > 0) {
                promoData = JSON.parse(JSON.stringify(this.promoResponseData))
                if(this.enablePaginateData) {
                    let selectedPage = this.$refs.paginationRef?.selectedPage
                    promoData = promoData.slice((selectedPage - 1) * 10, selectedPage * 10)
                }
                promoData = this.reponseModifier(JSON.parse(JSON.stringify(promoData)))
                const updateTotalRecords = () => {
                    if(this.$refs.paginationRef) {
                        this.$refs.paginationRef.totalRecords = this.promoResponseData?.length ?? 0
                    }
                }
                if (this.enablePaginateData) {
                    updateTotalRecords();
                }
            }
            return promoData
        }
    }
}
</script>